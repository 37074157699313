import { get as lodashGet } from 'lodash-es';
import { createSelector } from 'reselect';

import { ITEM_TYPE_CATEGORY } from '@yojee/types';

import { getFormKeyPath, getFormKeyPathOfContainerFromItemKeyPath } from './components/helpers/formHelpers';
import {
  getAreOriginsDestinationsMatched,
  getOriginDestinationFromLegSteps,
} from './components/OrderForm/BookingInfoSections/BookingInfoSection/utils';

export const getCompanyInfoSelector = (state) => state.orderBooking.companyInfo?.data;
export const getCompanyTimezoneSelector = (state) => state.orderBooking.companyInfo?.data?.timezone;
export const getCompanyCurrencySelector = (state) => state.orderBooking.companyInfo?.data?.currency;
export const getCompanyDeliverOptionsSelector = (state) => state.orderBooking.companyInfo?.data?.['delivery_options'];
export const getOrganisationsSelector = (state) => state.orderBooking.organisations?.data;
export const getOrganisationalUnitsSelector = (state) => state.orderBooking.organisationaUnits?.data?.data;
export const getNonOperationalZonesSelector = (state) => state.orderBooking.nonOperationalZones?.data;
export const getTaskTypesSelector = (state) => state.orderBooking.taskTypes?.data;
export const getContainerTypesSelector = (state) => state.orderBooking.containerTypes?.data?.data;
export const getSelectedServiceTypeSelector = (state) => state.orderBooking?.selectedServiceType;
export const getTemplateDetailSelector = (state) => state.orderBooking.templateDetail?.data;
export const getTemplateDetailIdSelector = (state) => state.orderBooking.templateDetail?.data?.id;
export const isLoadingTemplateDetailSelector = (state) => state.orderBooking.templateDetail?.status === 'loading';
export const getOrderFormModelsSelector = (state) => state.orderBooking.orderFormModels;
export const getFormKeysMetaDataSelector = (state) => state.orderBooking.formKeysMetaDataMap;
export const isFormModelHaveErrorSelector = (state, formKeyPath) =>
  state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ?? false;
export const isItemDetailModelsHaveErrorSelector = (state, formKeyPath) => {
  const containerFormKeyPath = getFormKeyPathOfContainerFromItemKeyPath(formKeyPath);
  return (
    state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ||
    state.orderBooking.formKeysMetaDataMap?.[containerFormKeyPath]?.isHaveError ||
    false
  );
};
export const isVesselSectionHaveErrorSelector = (state) => {
  const formKeyPath = getFormKeyPath({ type: 'vessel' });
  return state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ?? false;
};
export const isLegSectionHaveErrorSelector = (state, bookingInfoSectionIndex, legIndex) => {
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, legIndex });
  return state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ?? false;
};
export const isItemDetailSectionHaveErrorSelector = (state, bookingInfoSectionIndex) => {
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, type: 'items' });
  return state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ?? false;
};
export const isBookingInformationSectionHaveErrorSelector = (state, bookingInfoSectionIndex) => {
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex });
  return state.orderBooking.formKeysMetaDataMap?.[formKeyPath]?.isHaveError ?? false;
};
export const getOrderItemDetailsSelector = (state, bookingInfoSectionIndex) =>
  state.orderBooking.orderFormModels?.bookingInfoSections?.[bookingInfoSectionIndex]?.itemDetails;
export const getOrderLegsSelector = (state, bookingInfoSectionIndex) =>
  state.orderBooking.orderFormModels?.bookingInfoSections?.[bookingInfoSectionIndex]?.legs;

const orderUpstreamMetadataSelector = (state) => state.orderBooking.orderFormModels?.upstreamMetadata;
export const areOriginsDestinationsMatchedSelector = (bookingInfoSectionIndex) => (state) => {
  const legs = getOrderLegsSelector(state, bookingInfoSectionIndex);
  const upstreamMetadata = orderUpstreamMetadataSelector(state);
  return getAreOriginsDestinationsMatched(legs, upstreamMetadata);
};

export const getOriginDestinationSelector = (state, bookingInfoSectionIndex) => {
  const legs = getOrderLegsSelector(state, bookingInfoSectionIndex);
  return getOriginDestinationFromLegSteps(legs);
};

export const getOrderItemSteps = (state) => state.orderBooking.orderFormModels?.orderItemSteps;
export const getOrderBookingInfoSectionsSelector = (state) => state.orderBooking.orderFormModels?.bookingInfoSections;
export const getOrderInfoSelector = (state) => state.orderBooking.orderFormModels?.order;

export const getVesselVoyageInfoSelector = (state) => state.orderBooking.orderFormModels?.order_voyage_info;
export const getOrderItems = (state) => state.orderBooking.orderDetail?.data?.order_items || [];
export const getOrderStatusSelector = (state) => state.orderBooking.orderFormModels?.order?.status;

export const getOrderSenderSelector = (state) => {
  const organisationalUnitId = state.orderBooking.orderFormModels?.order?.organisational_unit?.id;
  let senderData = state.orderBooking.orderFormModels?.sender;

  if (senderData && organisationalUnitId) {
    senderData = {
      ...senderData,
      organisational_unit_id: organisationalUnitId,
    };
  }

  return senderData;
};

export const getOrderSenderId = (state) => state.orderBooking.orderFormModels?.sender?.id;
export const getBookingPortalSenderId = (state) => state.login?.accountInfo?.id;
export const getOrderTemplateIdSelector = (state) => state.orderBooking.orderFormModels?.order?.template_id;
export const getOrderIdSelector = (state) => state.orderBooking.orderFormModels?.order?.id;
export const isOrderFromTransferSelector = (state) =>
  state.orderBooking.orderFormModels?.order?.is_from_transfer ?? false;
export const getOrderGroupDataSelector = (state) => state.orderBooking.orderDetail?.data?.['order_group'];
export const getOriginalOrderFormModelsSelector = (state) => state.orderBooking.orderDetail?.originalOrderFormModels;
export const getOriginalOrderItemDetailsSelector = (state, bookingInfoSectionIndex) =>
  state.orderBooking.orderDetail?.originalOrderFormModels?.bookingInfoSections?.[bookingInfoSectionIndex]?.itemDetails;
export const isLoadingOrderDetailSelector = (state) => state.orderBooking.orderDetail?.status === 'loading';
export const getChargesDataSelector = (state) => state.orderBooking.charges?.data;
export const getSenderChargesDataSelector = (state) => state.orderBooking.charges?.data?.charges;
export const getChargesWarningsSelector = (state) => state.orderBooking.charges?.data?.errors;
export const getChargesTotalSelector = (state) => state.orderBooking.charges?.data?.total;
export const getChargesErrorSelector = (state) => state.orderBooking.charges?.error;
export const isLoadingChargesDataSelector = (state) => state.orderBooking.charges?.status === 'loading';
export const getFormRefsSelector = (state) => state.orderBooking.formRefs;
export const getFormRefsFromPathSelector = (state, formKeyPath, defaultValue) =>
  lodashGet(state.orderBooking?.formRefs, formKeyPath, defaultValue);
export const getOrderFieldTemplateIdSelector = (state) => state.orderBooking.orderFieldTemplate?.id;
export const getOrderFieldTemplateSenderSelector = (state) => state.orderBooking.orderFieldTemplateSender;
export const isInProgressDeleteOrderFieldTemplateSelector = (state) =>
  state.orderBooking.inProgress?.deleteOrderFieldTemplate;
export const isInProgressUpdateOrderFieldTemplateSelector = (state) =>
  state.orderBooking.inProgress?.updateOrderFieldTemplate;
export const isInProgressCreateOrderFieldTemplateSelector = (state) =>
  state.orderBooking.inProgress?.createOrderFieldTemplate;
export const isUseNewBookingSelector = (state) =>
  state?.auth?.dispatcher_info?.data?.company?.display_settings?.admin_copy?.['use_new_booking_experience'];
export const isUseNewBookingInManageSelector = (state) =>
  state.company?.companyData?.display_settings?.admin_copy?.['use_new_booking_experience'];
export const getAllTasks = (state) => state.orderBooking.tasks?.data?.tasks;
export const getItemIdTasksMap = (state) => state.orderBooking.tasks?.data?.itemIdTasksMap;
export const getItemStepKeyTaskMap = (state) => state.orderBooking.tasks?.data?.itemStepKeyTaskMap;
export const getAllTasksOfOrder = (state) => state.orderBooking.tasks?.data?.tasks;
export const getTemplateTaskSequence = (state) => state.orderBooking.templateDetail?.data?.settings?.task_sequence;
export const getOrderNumber = (state) => state.orderBooking.orderFormModels?.order?.number;
export const isOrderAlreadyPaidSelector = (state) => !!state.orderBooking.orderFormModels?.order?.['paid_at'];
export const isTransferredOrderAcceptedSelector = (state) =>
  state.orderBooking.orderFormModels?.order?.['is_from_transfer'] &&
  !!state.orderBooking.orderFormModels?.order?.['accepted_at'];
export const getIsPreviewTransferredOrder = (state) =>
  state.orderBooking.orderFormModels?.order?.['is_from_transfer'] &&
  !state.orderBooking.orderFormModels?.order?.['accepted_at'];

export const getDisplaySettingsSelector = (state) => state?.auth?.dispatcher_info?.data?.company?.display_settings;

export const getServiceTypesSelector = (state) => {
  if (state.orderBooking?.serviceTypes?.data?.service_types) {
    let senderId = state.orderBooking.orderFormModels?.sender?.id;

    if (!senderId && state.login?.accountInfo?.senderType) {
      senderId = state.login?.accountInfo?.id;
    }

    const selectedServiceType = state.orderBooking?.selectedServiceType;
    const orderId = state.orderBooking.orderFormModels?.order?.id;
    const updatedList = state.orderBooking.serviceTypes.data.service_types.filter((serviceType) => {
      return (
        serviceType.sender_link_type === 'all' ||
        (orderId && selectedServiceType && selectedServiceType.id === serviceType.id) ||
        (serviceType.sender_link_type === 'specific' &&
          senderId &&
          (serviceType.linked_senders || []).find((linkedSender) => linkedSender.id === senderId))
      );
    });

    return {
      ...state.orderBooking.serviceTypes,
      data: {
        ...state.orderBooking.serviceTypes.data,
        service_types: updatedList,
      },
    };
  }

  return state.orderBooking?.serviceTypes;
};

export const getItemTypesSelector = createSelector(
  getOrderItems,
  (state) => state.orderBooking.itemTypes?.data || [],
  (orderItems, originalItemTypes) => {
    const itemTypes = originalItemTypes.map(({ name, type }) => ({ name, type }));

    // Inherit container item type from upstream data
    orderItems.forEach((orderItem) => {
      if (orderItem.item_container && orderItem.is_using_container) {
        const itemTypeName = orderItem.payload_type;
        const itemTypeData = itemTypes.find(({ name }) => name === itemTypeName);

        if (itemTypeData) {
          itemTypeData.type = ITEM_TYPE_CATEGORY.container;
        } else {
          itemTypes.push({
            name: itemTypeName,
            type: ITEM_TYPE_CATEGORY.container,
          });
        }
      }
    });

    return itemTypes;
  }
);

export const isServiceTypeChangedSelector = (state) => {
  const originalOrder = state.orderBooking.orderDetail?.originalOrderFormModels?.order;
  const modifiedOrder = state.orderBooking.orderFormModels?.order;

  // TODO(@linhyojee): there is a legacy bug where the service_type_id
  // is not updated accordingly when changing the service type!
  return originalOrder && modifiedOrder && originalOrder?.service_type_name !== modifiedOrder?.service_type_name;
};

export const isServiceTypeEditableSelector = (state) => {
  const orderData = state.orderBooking.orderDetail?.data;
  // If it's a new form/order, it's always editable
  if (!orderData) {
    return true;
  } else {
    // If there's an existing order check that its service type is editable
    return state.orderBooking.orderDetail?.data?.editable_service_type;
  }
};

export const getTemplates = (state) => state.orderBooking.templates?.data;
