const defaultState = {
  isShown: false,
  wasOpenBefore: false,
  channels: [],
  selectedChannel: null,
  totalUnreadCount: 0,
  loading: {
    requestChannelsList: false,
    requestChannelInfo: false,
    requestDriverChannelInfo: false,
    sendMessage: false,
  },
  isFullscreen: false,
  messageError: null,
  shouldUpdateSelectedChannel: false,
};

export const init = () => {
  return defaultState;
};

// Helpers

const updateLastMessageForChannel = (channels, { channelId, lastMessage }) => {
  return (channels || []).map((channel) => {
    if (channel.id !== channelId || !lastMessage) {
      return channel;
    }

    return { ...channel, last_message: lastMessage };
  });
};

const putMessageOntoChannel = (channel, message) => {
  if (!channel || !message) return channel;

  const oldMessages = channel.messages || [];
  const found = oldMessages.some((msg) => msg.id === message.id);

  if (found) return channel;

  return {
    ...channel,
    messages: [message, ...oldMessages],
  };
};

const popMessageOffChannel = (channel, message) => {
  if (!channel || !message) return channel;

  const oldMessages = channel.messages || [];

  return {
    ...channel,
    messages: oldMessages.filter((msg) => msg.id !== message.id),
  };
};

const getTotalUnreadCount = (channels, excludedChannelId) => {
  return (channels || []).reduce((total, currentChannel) => {
    const { id, unread_count } = currentChannel;
    return id === excludedChannelId ? total : total + unread_count;
  }, 0);
};

const putNewChannelOntoChannelList = (channelList, newChannel) => {
  const found = channelList.some((channel) => channel.id === newChannel.id);

  if (found) return channelList;

  const brandNewChannel = {
    id: newChannel.id,
    unread_count: 0,
    last_message: null,
    members: newChannel.members,
  };

  return [brandNewChannel, ...channelList];
};

// Hanlders

const ACTION_HANDLERS = {
  REQUEST_CHANNELS_LIST_SUCCESS: (state, { data }) => {
    return {
      ...state,
      channels: data,
      totalUnreadCount: getTotalUnreadCount(data),
      loading: {
        ...state.loading,
        requestChannelsList: false,
      },
    };
  },
  REQUEST_CHANNELS_LIST_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        requestChannelsList: false,
      },
    };
  },
  REQUEST_CHANNEL_INFO_SUCCESS: (state, { data }) => {
    const newChannels = putNewChannelOntoChannelList(state.channels, data);

    return {
      ...state,
      channels: newChannels,
      selectedChannel: data,
      loading: {
        ...state.loading,
        requestChannelInfo: false,
        requestDriverChannelInfo: false,
      },
    };
  },
  REQUEST_CHANNEL_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        requestChannelInfo: false,
        requestDriverChannelInfo: false,
      },
    };
  },
  SEND_MESSAGE_SUCCESS: (state, { data, channelId, optimisticResponse }) => {
    const { channels, selectedChannel } = state;
    const selectedChannelId = selectedChannel?.id;

    let updatedChannels = channels;
    let channel = selectedChannel;

    // Remove the optimistic response and put the tru data received
    // from the server back on.
    if (selectedChannelId && selectedChannelId === channelId) {
      channel = popMessageOffChannel(selectedChannel, optimisticResponse);
      channel = putMessageOntoChannel(channel, data);

      updatedChannels = updateLastMessageForChannel(channels, {
        channelId: selectedChannelId,
        lastMessage: {
          ...data,
          // A trick to keep the last message consistent
          user_profile_id: data.user_profile.user_profile_id,
        },
      });
    }

    return {
      ...state,
      channels: updatedChannels,
      selectedChannel: channel,
      loading: {
        ...state.loading,
        sendMessage: false,
        sendAttachmentMessage: false,
      },
      messageError: null,
    };
  },
  SEND_MESSAGE_FAILED: (state, { channelId, optimisticResponse }) => {
    const { channels, selectedChannel } = state;
    const selectedChannelId = selectedChannel?.id;

    let updatedChannels = channels;
    let channel = selectedChannel;

    // Remove the previous optimistic response and restore the last message.
    if (selectedChannelId && selectedChannelId === channelId) {
      channel = popMessageOffChannel(channel, optimisticResponse);

      const lastMessage = channel.messages.length ? channel.messages[0] : null;

      updatedChannels = (channels || []).filter((c) => {
        if (c.id !== selectedChannelId) return c;
        return { ...c, last_message: lastMessage };
      });
    }

    return {
      ...state,
      channels: updatedChannels,
      selectedChannel: channel,
      loading: {
        ...state.loading,
        sendMessage: false,
        sendAttachmentMessage: false,
      },
    };
  },
  GET_NEW_MESSAGE_NOTIFICATION: (state, { message }) => {
    const { channels, selectedChannel } = state;
    let newChannels = [];
    let newSelectedChannel = null;
    let totalUnreadCount = getTotalUnreadCount(channels);
    let shouldUpdateSelectedChannel = state.shouldUpdateSelectedChannel;

    if (selectedChannel && selectedChannel.id === message.channel.id) {
      newSelectedChannel = putMessageOntoChannel(selectedChannel, message);

      newChannels = updateLastMessageForChannel(channels, {
        channelId: selectedChannel.id,
        lastMessage: message,
      });

      shouldUpdateSelectedChannel = true;
    } else {
      const channel = channels ? channels.find(({ id }) => id === message.channel.id) : null;
      if (channel) {
        totalUnreadCount++;

        newChannels = [
          {
            ...channel,
            last_message: message,
            unread_count: (channel.unread_count || 0) + 1,
          },
          ...channels.filter(({ id }) => id !== message.channel.id),
        ];
      }
    }

    return {
      ...state,
      shouldUpdateSelectedChannel,
      channels: newChannels,
      selectedChannel: newSelectedChannel || selectedChannel,
      totalUnreadCount: totalUnreadCount,
      messageError: null,
    };
  },
  UPDATE_CHANNEL_SUCCESS: (state, { data: { channelId } }) => {
    return {
      ...state,
      channels: state.channels.map((channel) => {
        if (channel.id === channelId) {
          return {
            ...channel,
            unread_count: 0,
          };
        }
        return channel;
      }),
      totalUnreadCount: getTotalUnreadCount(state.channels, channelId),
      shouldUpdateSelectedChannel: false,
    };
  },
  CLEAR_SELECTED_CHANNEL_INFO: (state) => {
    return {
      ...state,
      selectedChannel: null,
      messageError: null,
    };
  },
  TOGGLE_CHAT: (state) => {
    return {
      ...state,
      isShown: !state.isShown,
      messageError: null,
    };
  },
  SAVE_CURRENT_OPEN_STATE_AND_CLOSE_CHAT: (state) => {
    return {
      ...state,
      isShown: false,
      wasOpenBefore: state.isShown,
    };
  },
  REVERT_TO_PREVIOUS_OPEN_STATE: (state) => {
    return {
      ...state,
      isShown: state.wasOpenBefore,
    };
  },
  CHAT_LOADING: (state, { key, channelId, optimisticResponse }) => {
    const { channels, selectedChannel } = state;
    const selectedChannelId = selectedChannel?.id;

    let updatedChannels = channels;
    let channel = selectedChannel;

    // Temporarily make the optimistic response the latest message of
    // the selected channel. This message will be remove once the
    // server responds.
    if (selectedChannelId && selectedChannelId === channelId) {
      updatedChannels = updateLastMessageForChannel(channels, {
        channelId: selectedChannelId,
        lastMessage: optimisticResponse,
      });

      channel = putMessageOntoChannel(selectedChannel, optimisticResponse);
    }

    return {
      ...state,
      loading: { ...state.loading, [key]: true },
      channels: updatedChannels,
      selectedChannel: channel,
      messageError: null,
    };
  },
  TOGGLE_FULLSCREEN: (state) => ({
    ...state,
    isFullscreen: !state.isFullscreen,
  }),
  SET_MESSAGE_ERROR: (state, { payload }) => ({
    ...state,
    messageError: {
      kind: payload.kind,
      text: payload.text,
    },
  }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
