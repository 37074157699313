import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class VehicleTypesService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  fetchVehicleTypes = (page = 1, pageSize = undefined) => {
    const params = {
      page,
      page_size: pageSize,
    };

    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/vehicle_types2'), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const vehicleTypesService = new VehicleTypesService({ auth: authService });
