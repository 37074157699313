import { cloneDeep, isEqual } from 'lodash';

import { isSubTaskRuleDataListsEqual } from '@yojee/helpers/subTaskRuleHelpers';

import actionTypes from '../saga/actionTypes';

export const defaultSharingDriverLocationState = {
  shareDriverInfo: {
    loading: [],
    errorMessage: {},
    successMessage: {},
  },
};

// State to keep track of statuses when toggling the Partner Documentation
// Switch. Each child object is responsible for the corresponding state of
// the Switch (ON/OFF), and they all have the following properties:
//
// - `loading` - is an array of partnershipId such that the request is still
//   in progress.
//
// - `errorMessage` - is an object of the form `{[partnershipId]: msg}`.
//   It is used to keep track of the failed requests.
//
// - `successMessage` - is an object of the form `{[partnershipId]: msg}`.
//   It is used to keep track of the successful requests.
export const defaultTogglingDownstreamDocumentationState = {
  // Switch ON
  acceptDownstreamDocumentation: {
    loading: [],
    errorMessage: {},
    successMessage: {},
  },
  // Switch OFF
  rejectDownstreamDocumentation: {
    loading: [],
    errorMessage: {},
    successMessage: {},
  },
};

const initPartnerDetailState = Object.freeze({
  isLoading: false,
  isDirty: false,
  initial: null,
  current: null,
  errorMessage: null,
  successMessage: null,
});

const defaultState = {
  list: [],
  partnerRequestsSent: [],
  partnerRequestsReceived: [],
  partnerInvitations: [],
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  filter: {
    query: undefined,
    page: 1,
    page_size: 25,
    includeInactiveState: true,
  },
  errorMessage: undefined,
  successMessage: undefined,
  openDialog: false,
  inProgress: {
    requestGetList: false,
    requestGetPartnerRequestsSent: false,
    requestGetPartnerRequestsReceived: false,
    requestGetPartnerInvitations: false,
    requestWithdrawPartnerInvitation: false,
    requestResendPartnerInvitation: false,
    requestSendInviteToPartner: false,
    requestWithdrawPartnerRequest: false,
    requestAcceptInvitation: false,
    requestRejectInvitation: false,
    requestMarkPartnerActive: false,
    requestMarkPartnerInactive: false,
  },
  ...defaultSharingDriverLocationState,
  ...defaultTogglingDownstreamDocumentationState,
  partnershipDetail: { ...initPartnerDetailState },
  partnerOrganisationalUnits: [],
  organisationalUnits: [],
};

const ACTION_HANDLERS = {
  UPDATE_SHARING_DRIVER_LOCATION_MESSAGE: (state, { partnerShipId }) => {
    delete state.shareDriverInfo.successMessage[partnerShipId];
    delete state.shareDriverInfo.errorMessage[partnerShipId];

    return { ...state };
  },
  CLEAR_TOGGLE_DOWNSTREAM_DOCUMENTATION_MESSAGE: (state, { partnershipId }) => {
    // Clear messages after accepting downstream documentation for the
    // Partnership whose ID is given by `partnershipId`.
    delete state.acceptDownstreamDocumentation.successMessage[partnershipId];
    delete state.acceptDownstreamDocumentation.errorMessage[partnershipId];

    // Clear messages after rejecting downstream documentation for the
    // Partnership whose ID is given by `partnershipId`.
    delete state.rejectDownstreamDocumentation.successMessage[partnershipId];
    delete state.rejectDownstreamDocumentation.errorMessage[partnershipId];

    return { ...state };
  },
  [actionTypes.updateSharingDriverLocation().loading()]: (state, { key, partnerShipId }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: [...state[key].loading, partnerShipId].concat([]),
    },
  }),
  [actionTypes.updateSharingDriverLocation().success()]: (state, { key, partnerShipId, message }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: state[key].loading.filter((id) => id !== partnerShipId),
      successMessage: { ...state[key].successMessage, [partnerShipId]: message },
    },
  }),
  [actionTypes.updateSharingDriverLocation().failed()]: (state, { key, partnerShipId, message }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: state[key].loading.filter((id) => id !== partnerShipId),
      errorMessage: { ...state[key].errorMessage, [partnerShipId]: message },
    },
  }),
  [actionTypes.toggleDownstreamDocumentation().loading()]: (state, { key, partnershipId }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: [...state[key].loading, partnershipId],
    },
  }),
  [actionTypes.toggleDownstreamDocumentation().success()]: (state, { key, partnershipId, message }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: state[key].loading.filter((id) => id !== partnershipId),
      successMessage: { ...state[key].successMessage, [partnershipId]: message },
    },
  }),
  [actionTypes.toggleDownstreamDocumentation().failed()]: (state, { key, partnershipId, message }) => ({
    ...state,
    [key]: {
      ...state[key],
      loading: state[key].loading.filter((id) => id !== partnershipId),
      errorMessage: { ...state[key].errorMessage, [partnershipId]: message },
    },
  }),
  [actionTypes.startProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: true,
    },
  }),
  [actionTypes.endProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: false,
    },
  }),
  [actionTypes.updateState()]: (state, { newState }) => {
    return {
      ...state,
      ...newState,
    };
  },
  [actionTypes.setError()]: (state, { message }) => ({
    ...state,
    errorMessage: message,
  }),
  [actionTypes.resetState()]: () => defaultState,
  [actionTypes.getList().success()]: (state, { data, pagination }) => ({
    ...state,
    list: data,
    pagination,
    inProgress: {
      ...state.inProgress,
      requestGetList: false,
    },
  }),
  [actionTypes.getPartnerRequestsSent().success()]: (state, { data }) => ({
    ...state,
    partnerRequestsSent: data,
    inProgress: {
      ...state.inProgress,
      requestGetPartnerRequestsSent: false,
    },
  }),
  [actionTypes.getPartnerRequestsReceived().success()]: (state, { data }) => ({
    ...state,
    partnerRequestsReceived: data,
    inProgress: {
      ...state.inProgress,
      requestGetPartnerRequestsReceived: false,
    },
  }),
  [actionTypes.getPartnerInvitations().success()]: (state, { data }) => ({
    ...state,
    partnerInvitations: data,
    inProgress: {
      ...state.inProgress,
      requestGetPartnerInvitations: false,
    },
  }),
  [actionTypes.withdrawPartnerInvitation().success()]: (state) => ({
    ...state,
    successMessage: 'Request successfully cancelled. Your invitation request is no longer valid.',
    inProgress: {
      ...state.inProgress,
      requestWithdrawPartnerInvitation: false,
    },
  }),
  [actionTypes.resendPartnerInvitation().success()]: (state) => ({
    ...state,
    successMessage: 'Resend invitation successfully!',
    inProgress: {
      ...state.inProgress,
      requestResendPartnerInvitation: false,
    },
  }),
  [actionTypes.sendInviteToPartner().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestSendInviteToPartner: false,
      },
    };
  },
  [actionTypes.withdrawPartnerRequest().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestWithdrawPartnerRequest: false,
      },
    };
  },
  [actionTypes.acceptInvitation().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestAcceptInvitation: false,
      },
    };
  },
  [actionTypes.rejectInvitation().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestRejectInvitation: false,
      },
    };
  },
  [actionTypes.markPartnerActive().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestMarkPartnerActive: false,
      },
    };
  },
  [actionTypes.markPartnerInactive().success()]: (state, { data }) => {
    return {
      ...state,
      successMessage: data.message,
      inProgress: {
        ...state.inProgress,
        requestMarkPartnerInactive: false,
      },
    };
  },
  RESET_FILTER: (state) => {
    state.filter = defaultState.filter;
    return state;
  },
  [actionTypes.getPartnershipDetail().loading()]: (state) => ({
    ...state,
    partnershipDetail: {
      ...initPartnerDetailState,
      isLoading: true,
    },
  }),
  [actionTypes.getPartnershipDetail().success()]: (state, { partnership }) => ({
    ...state,
    partnershipDetail: {
      ...initPartnerDetailState,
      initial: partnership,
      current: partnership,
    },
  }),
  [actionTypes.getPartnershipDetail().failed()]: (state) => ({
    ...state,
    // Deliberately ignore the error message here!
    partnershipDetail: { ...initPartnerDetailState },
  }),
  [actionTypes.clearPartnershipDetail()]: (state) => ({
    ...state,
    partnershipDetail: { ...initPartnerDetailState },
  }),
  [actionTypes.updateCurrentPartnershipDetail()]: (state, { partnership }) => {
    const initialPartnership = state.partnershipDetail.initial;
    const currentPartnership = partnership;
    const isDirty = !isStatesEqual(initialPartnership, currentPartnership);

    return {
      ...state,
      partnershipDetail: {
        ...state.partnershipDetail,
        current: currentPartnership,
        isDirty: isDirty,
      },
    };
  },
  [actionTypes.resetCurrentPartnershipDetail()]: (state) => {
    const initialPartnership = state.partnershipDetail.initial;

    return {
      ...state,
      partnershipDetail: {
        ...state.partnershipDetail,
        current: initialPartnership,
        isDirty: false,
      },
    };
  },
  [actionTypes.saveCurrentPartnershipSettings().loading()]: (state, { current }) => ({
    ...state,
    partnershipDetail: {
      ...state.partnershipDetail,
      // Optimistic response
      initial: current,
      current: current,
      isDirty: false,
      errorMessage: null,
      successMessage: null,
    },
  }),
  [actionTypes.saveCurrentPartnershipSettings().success()]: (state, { message, current }) => ({
    ...state,
    partnershipDetail: {
      ...state.partnershipDetail,
      initial: current,
      current: current,
      isDirty: false,
      errorMessage: null,
      successMessage: message,
    },
  }),
  [actionTypes.saveCurrentPartnershipSettings().failed()]: (state, { message, initial, current }) => ({
    ...state,
    partnershipDetail: {
      ...state.partnershipDetail,
      // Revert optimistic response
      initial: initial,
      current: current,
      isDirty: !isStatesEqual(initial, current),
      errorMessage: message,
      successMessage: null,
    },
  }),
  [actionTypes.getPartnerOrganisationalUnits().success()]: (state, { partnerOrganisationalUnits }) => {
    return {
      ...state,
      partnerOrganisationalUnits,
    };
  },
  [actionTypes.getOrganisationalUnits().success()]: (state, { organisationalUnits }) => {
    return {
      ...state,
      organisationalUnits,
    };
  },
};

const isStatesEqual = (state1, state2) => {
  if (!state1 || !state2) {
    return false;
  }

  const clonedState1 = cloneDeep(state1);
  const clonedState2 = cloneDeep(state2);

  if (
    !isSubTaskRuleDataListsEqual(clonedState1.sub_task_rules, clonedState2.sub_task_rules) ||
    !isSubTaskRuleDataListsEqual(
      clonedState1.inverse_partnership?.sub_task_rules,
      clonedState2.inverse_partnership?.sub_task_rules
    )
  ) {
    return false;
  }

  delete clonedState1.sub_task_rules;
  delete clonedState2.sub_task_rules;
  delete clonedState1.inverse_partnership?.sub_task_rules;
  delete clonedState2.inverse_partnership?.sub_task_rules;

  return isEqual(clonedState1, clonedState2);
};

export const PartnerManagementReducer = {
  partner: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  },
};
