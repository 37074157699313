import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class PartnerService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  searchPartner = ({
    query,
    page = 1,
    page_size = 5000,
    type,
    includeInactiveCompany = false,
    includeInactiveState = false,
    include_all_ous = false,
  } = {}) => {
    const state = includeInactiveState ? undefined : 'active';
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partners'), {
        page,
        page_size,
        state,
        type,
        include_inactive_company: includeInactiveCompany,
        q: query,
        include_all_ous: include_all_ous,
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartnerRequestsSent = ({ query, page = 1, page_size = 5000 } = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partners/invites/sent'), {
        page,
        page_size,
        q: query,
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartnerRequestsReceive = ({ query, page = 1, page_size = 5000 } = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partners/invites/received'), {
        page,
        page_size,
        q: query,
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartnerInvitations = ({ state = 'sent', page = 1, page_size = 5000 } = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partner_invitations'), {
        page,
        page_size,
        state,
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  inviteMultiplePartner = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_invitations'), {
        params: params,
      })
      .catch((error) => this.handleError(error));
  };

  sendInviteToPartner = ({ requestViewActivities, partnerCIP, retrieveDownstreamDocumentation } = {}) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${partnerCIP}/send_invite`), {
        request_view_activities: requestViewActivities,
        retrieve_downstream_documentation: retrieveDownstreamDocumentation,
      })
      .catch((error) => this.handleError(error));
  };

  withdrawPartnerInvitation = (invitationCode) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partner_invitations/${invitationCode}/cancel`))
      .catch((error) => this.handleError(error));
  };

  resendPartnerInvitation = (invitationCode) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/partner_invitations/${invitationCode}/resend`))
      .catch((error) => this.handleError(error));
  };

  withdrawPartnerRequest = (cip) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${cip}/cancel_invite`))
      .catch((error) => this.handleError(error));
  };

  acceptInvitation = ({ cip, options }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${cip}/accept_invite`), {
        options: options,
      })
      .catch((error) => this.handleError(error));
  };

  rejectInvitation = (cip) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${cip}/reject_invite`))
      .catch((error) => this.handleError(error));
  };

  markActive = (partnershipId) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${partnershipId}/mark_active`))
      .catch((error) => this.handleError(error));
  };

  markInactive = (partnershipId) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/partners/${partnershipId}/mark_inactive`))
      .catch((error) => this.handleError(error));
  };

  getAllServiceTypeMappings = (partnerCompanyId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/partner_companies/${partnerCompanyId}/service_type_mappings`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  doServiceTypeMappingBulkActions = (partnerCompanyId, actionsData) => {
    return this.authService
      .post(
        this.getUmbrellaApiUrl(`dispatcher/partner_companies/${partnerCompanyId}/service_type_mappings/bulk_actions`),
        {
          actions: actionsData,
        }
      )
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartnershipAuditLogs = (partnerCompanyId, timezone) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/partners/${partnerCompanyId}/logs`), { timezone })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getPartnerIntegrationProfiles = (partnerCompanyId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/partner_integrations/${partnerCompanyId}`))
      .then((response) => {
        return this.extractData(response.data);
      })
      .catch((error) => this.handleError(error));
  };

  updatePartnerIntegrationProfiles = (partnerCompanyId, payload) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/partner_integrations/${partnerCompanyId}/edit`), payload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  checkServiceTypesIsMapping = (partnerCompanyId, serviceTypeIds = []) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl(
          `dispatcher/partner_companies/${partnerCompanyId}/service_type_mappings/check_service_type_is_mapping`
        ),
        {
          service_type_ids: serviceTypeIds,
        }
      )
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  shareDriverLocation = (partnerShipId) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/partners/${partnerShipId}/share_driver_location`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  withholdDriverLocation = (partnerShipId) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/partners/${partnerShipId}/withhold_driver_location`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  updatePartnershipDetails = (partnerShipId, options) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/partners/${partnerShipId}/update_partnership_details`), { data: options })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  upstreamPartnerDriverWorkflow = (partnerShipId, options) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrlV4(`company/partners/${partnerShipId}/update_upstream_partnership_driver_workflow`),
        options
      )
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  acceptDownstreamDocumentation = (partnershipId) => {
    return this.authService
      .patch(this.getUmbrellaApiUrlV4(`company/partners/${partnershipId}/update_partner_documentation_config`), {
        data: { retrieve_downstream_documentation: true },
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  rejectDownstreamDocumentation = (partnershipId) => {
    return this.authService
      .patch(this.getUmbrellaApiUrlV4(`company/partners/${partnershipId}/update_partner_documentation_config`), {
        data: { retrieve_downstream_documentation: false },
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getPartnershipDetail = (partnerShipId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/partners/${partnerShipId}`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  updatePartnerContactDetails = ({ partnershipId, contactDetails }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/partners/${partnershipId}/update_partner_contact_details`), {
        ...contactDetails,
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getPartnerOrganisationalUnits = ({ partnershipId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/partners/${partnershipId}/upstream_organisational_units`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const partnerService = new PartnerService({ authService });
