import BaseActionTypes from '@yojee/helpers/BaseActionTypes';

class ActionTypes extends BaseActionTypes {
  getPartnerRequestsSent = () => this.customWithSubType('REQUEST_GET_PARTNER_REQUESTS_SENT');
  getPartnerRequestsReceived = () => this.customWithSubType('REQUEST_GET_PARTNER_REQUESTS_RECEIVED');
  getPartnerInvitations = () => this.customWithSubType('REQUEST_GET_PARTNER_INVITATIONS');
  withdrawPartnerInvitation = () => this.customWithSubType('REQUEST_WITHDRAW_PARTNER_INVITATION');
  resendPartnerInvitation = () => this.customWithSubType('REQUEST_RESEND_PARTNER_INVITATION');
  withdrawPartnerRequest = () => this.customWithSubType('REQUEST_WITHDRAW_PARTNER_REQUEST');
  sendInviteToPartner = () => this.customWithSubType('REQUEST_SEND_INVITE_TO_PARTNER');
  acceptInvitation = () => this.customWithSubType('REQUEST_ACCEPT_INVITATION');
  rejectInvitation = () => this.customWithSubType('REQUEST_REJECT_INVITATION');
  markPartnerActive = () => this.customWithSubType('REQUEST_MARK_PARTNER_ACTIVE');
  markPartnerInactive = () => this.customWithSubType('REQUEST_MARK_PARTNER_INACTIVE');
  shareDriverLocation = () => this.customWithSubType('SHARE_DRIVER_LOCATION');
  stopSharingDriverLocation = () => this.customWithSubType('STOP_SHARING_DRIVER_LOCATION');
  updateSharingDriverLocation = () => this.customWithSubType('UPDATE_DRIVER_LOCATION'); // Share or Stop sharing driver locations
  updateUpstreamPartnershipDriverWorkflow = () => this.customWithSubType('UPDATE_UPSTREAM_PARTNERSHIP_DRIVER_WORKFLOW');
  toggleDownstreamDocumentation = () => this.customWithSubType('TOGGLE_DOWNSTREAM_DOCUMENTATION');
  getPartnerOrganisationalUnits = () => this.customWithSubType('REQUEST_GET_PARTNER_ORGANISATIONAL_UNITS');
  getOrganisationalUnits = () => this.customWithSubType('REQUEST_GET_ORGANISATIONAL_UNITS');

  getPartnershipDetail = () => this.customWithSubType('GET_PARTNERSHIP_DETAIL');
  clearPartnershipDetail = () => this.custom('CLEAR_PARTNERSHIP_DETAIL');
  updateCurrentPartnershipDetail = () => this.custom('UPDATE_CURRENT_PARTNERSHIP_DETAIL');
  resetCurrentPartnershipDetail = () => this.custom('RESET_CURRENT_PARTNERSHIP_DETAIL');
  saveCurrentPartnershipSettings = () => this.customWithSubType('SAVE_CURRENT_PARTNERSHIP_SETTINGS');
}

export default new ActionTypes({ prefix: 'PARTNER_MANAGEMENT' });
