import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { partnerService } from '@yojee/api/partnerService';
import { organisationalServiceApi } from '@yojee/api/v4/organisationalUnitApi';

import actionTypes from './actionTypes';

export function* PartnerManagementSagas() {
  yield takeLatest(actionTypes.getList().start(), requestGetList);
  yield takeLatest(actionTypes.getPartnerRequestsSent().start(), requestGetListPartnerRequestsSent);
  yield takeLatest(actionTypes.getPartnerRequestsReceived().start(), requestGetListPartnerRequestsReceived);
  yield takeLatest(actionTypes.getPartnerInvitations().start(), requestGetListPartnerInvitations);
  yield takeLatest('CREATING_PARTNER_INVITATION_SUCCESS', requestGetListPartnerInvitations);
  yield takeLatest(actionTypes.withdrawPartnerInvitation().start(), requestWithdrawPartnerInvitation);
  yield takeLatest(actionTypes.resendPartnerInvitation().start(), requestResendPartnerInvitation);
  yield takeLatest(actionTypes.sendInviteToPartner().start(), requestSendInviteToPartner);
  yield takeLatest(actionTypes.withdrawPartnerRequest().start(), requestWithdrawPartnerRequest);
  yield takeLatest(actionTypes.acceptInvitation().start(), requestAcceptInvitation);
  yield takeLatest(actionTypes.rejectInvitation().start(), requestRejectInvitation);
  yield takeLatest(actionTypes.markPartnerActive().start(), requestMarkPartnerActive);
  yield takeLatest(actionTypes.markPartnerInactive().start(), requestMarkPartnerInactive);
  yield takeEvery(actionTypes.updateSharingDriverLocation().start(), updateSharingDriverLocation);
  yield takeEvery(
    actionTypes.updateUpstreamPartnershipDriverWorkflow().start(),
    updateUpstreamPartnershipDriverWorkflow
  );
  yield takeEvery(actionTypes.toggleDownstreamDocumentation().start(), toggleDownstreamDocumentation);
  yield takeLatest(actionTypes.getPartnershipDetail().start(), getPartnershipDetail);
  yield takeLatest(actionTypes.getPartnerOrganisationalUnits().start(), getPartnerOrganisationalUnits);
  yield takeLatest(actionTypes.getOrganisationalUnits().start(), getOrganisationalUnits);
}

function* requestGetList(action) {
  try {
    const payload = { ...action.payload, include_all_ous: true };
    yield put({ type: actionTypes.startProgress(), key: 'requestGetList' });
    const { data, pagination } = yield call(partnerService.searchPartner, payload);
    yield put({
      type: actionTypes.getList().success(),
      data,
      pagination,
    });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({ type: actionTypes.endProgress(), key: 'requestGetList' });
  }
}

function* requestGetListPartnerRequestsSent(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestGetPartnerRequestsSent',
    });
    const { data } = yield call(partnerService.getPartnerRequestsSent, action.payload);
    yield put({
      type: actionTypes.getPartnerRequestsSent().success(),
      data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestGetPartnerRequestsSent',
    });
  }
}

function* requestGetListPartnerRequestsReceived(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestGetPartnerRequestsReceived',
    });
    const { data } = yield call(partnerService.getPartnerRequestsReceive, action.payload);
    yield put({
      type: actionTypes.getPartnerRequestsReceived().success(),
      data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestGetPartnerRequestsReceived',
    });
  }
}

function* requestGetListPartnerInvitations(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestGetPartnerInvitations',
    });
    const { data } = yield call(partnerService.getPartnerInvitations, action.payload);
    yield put({
      type: actionTypes.getPartnerInvitations().success(),
      data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestGetPartnerInvitations',
    });
  }
}

function* requestWithdrawPartnerInvitation(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestWithdrawPartnerInvitation',
    });
    const { data } = yield call(partnerService.withdrawPartnerInvitation, action.payload);
    yield put({
      type: actionTypes.withdrawPartnerInvitation().success(),
      data,
    });
    yield put({ type: actionTypes.getPartnerInvitations().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestWithdrawPartnerInvitation',
    });
  }
}

function* requestResendPartnerInvitation(action) {
  const { invitationCode } = action.payload;

  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestResendPartnerInvitation',
    });
    const { data } = yield call(partnerService.resendPartnerInvitation, invitationCode);
    yield put({
      type: actionTypes.resendPartnerInvitation().success(),
      data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestResendPartnerInvitation',
    });
  }
}

function* requestSendInviteToPartner(action) {
  const { partnerCIP, requestViewActivities, retrieveDownstreamDocumentation } = action.payload;

  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestSendInviteToPartner',
    });
    const { data } = yield call(partnerService.sendInviteToPartner, {
      partnerCIP,
      requestViewActivities,
      retrieveDownstreamDocumentation,
    });
    yield put({
      type: actionTypes.sendInviteToPartner().success(),
      data,
    });
    yield put({ type: actionTypes.getPartnerInvitations().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestSendInviteToPartner',
    });
  }
}

function* requestWithdrawPartnerRequest(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestWithdrawPartnerRequest',
    });
    const { data } = yield call(partnerService.withdrawPartnerRequest, action.payload);
    yield put({
      type: actionTypes.withdrawPartnerRequest().success(),
      data,
    });
    yield put({ type: actionTypes.getPartnerRequestsSent().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestWithdrawPartnerRequest',
    });
  }
}

function* requestAcceptInvitation(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestAcceptInvitation',
    });
    const { data } = yield call(partnerService.acceptInvitation, action.payload);
    yield put({
      type: actionTypes.acceptInvitation().success(),
      data,
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestAcceptInvitation',
    });
    yield put({ type: actionTypes.getList().start() });
    yield put({ type: actionTypes.getPartnerRequestsReceived().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestAcceptInvitation',
    });
  }
}

function* requestRejectInvitation(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestRejectInvitation',
    });
    const { data } = yield call(partnerService.rejectInvitation, action.payload);
    yield put({
      type: actionTypes.rejectInvitation().success(),
      data,
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestRejectInvitation',
    });
    yield put({ type: actionTypes.getList().start() });
    yield put({ type: actionTypes.getPartnerRequestsReceived().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestRejectInvitation',
    });
  }
}

function* requestMarkPartnerActive(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestMarkPartnerActive',
    });
    const { data } = yield call(partnerService.markActive, action.payload);
    yield put({
      type: actionTypes.markPartnerActive().success(),
      data,
    });
    yield put({ type: actionTypes.getList().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestMarkPartnerActive',
    });
  }
}

function* requestMarkPartnerInactive(action) {
  try {
    yield put({
      type: actionTypes.startProgress(),
      key: 'requestMarkPartnerInactive',
    });
    const { data } = yield call(partnerService.markInactive, action.payload);
    yield put({
      type: actionTypes.markPartnerInactive().success(),
      data,
    });
    yield put({ type: actionTypes.getList().start() });
  } catch (error) {
    yield put({
      type: actionTypes.updateState(),
      newState: { errorMessage: error.message },
    });
    yield put({
      type: actionTypes.endProgress(),
      key: 'requestMarkPartnerInactive',
    });
  }
}

function* updateSharingDriverLocation({ key, partnerShipId, options }) {
  try {
    yield put({ type: actionTypes.updateSharingDriverLocation().loading(), key, partnerShipId });

    const { message } = yield call(partnerService.updatePartnershipDetails, partnerShipId, options);

    yield put({ type: actionTypes.updateSharingDriverLocation().success(), key, partnerShipId, message });
  } catch ({ message }) {
    yield put({ type: actionTypes.updateSharingDriverLocation().failed(), key, partnerShipId, message });
  }
}

function* updateUpstreamPartnershipDriverWorkflow({ key, partnerShipId, options }) {
  try {
    yield put({ type: actionTypes.updateUpstreamPartnershipDriverWorkflow().loading(), key, partnerShipId });

    const { message } = yield call(partnerService.updatePartnershipDetails, partnerShipId, options);

    yield put({ type: actionTypes.updateUpstreamPartnershipDriverWorkflow().success(), key, partnerShipId, message });
  } catch ({ message }) {
    yield put({ type: actionTypes.updateUpstreamPartnershipDriverWorkflow().failed(), key, partnerShipId, message });
  }
}

const toggleDownstreamDocumentationAPI = {
  acceptDownstreamDocumentation: partnerService.acceptDownstreamDocumentation,
  rejectDownstreamDocumentation: partnerService.rejectDownstreamDocumentation,
};

function* toggleDownstreamDocumentation({ key, partnershipId }) {
  try {
    yield put({
      type: actionTypes.toggleDownstreamDocumentation().loading(),
      key,
      partnershipId,
    });

    const apiService = toggleDownstreamDocumentationAPI[key];
    const { message } = yield call(apiService, partnershipId);

    yield put({
      type: actionTypes.toggleDownstreamDocumentation().success(),
      key,
      partnershipId,
      message,
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.toggleDownstreamDocumentation().failed(),
      key,
      partnershipId,
      message,
    });
  }
}

function* getPartnershipDetail({ partnershipId }) {
  try {
    yield put({
      type: actionTypes.getPartnershipDetail().loading(),
      partnershipId,
    });

    const apiService = partnerService.getPartnershipDetail;
    const partnership = yield call(apiService, partnershipId);

    yield put({
      type: actionTypes.getPartnershipDetail().success(),
      partnership,
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getPartnershipDetail().failed(),
      partnershipId,
      message,
    });
  }
}

function* getPartnerOrganisationalUnits({ partnershipId }) {
  try {
    yield put({
      type: actionTypes.getPartnerOrganisationalUnits().loading(),
      partnershipId,
    });

    const { data: partnerOrganisationalUnits } = yield call(partnerService.getPartnerOrganisationalUnits, {
      partnershipId,
    });

    yield put({
      type: actionTypes.getPartnerOrganisationalUnits().success(),
      partnerOrganisationalUnits,
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getPartnerOrganisationalUnits().failed(),
      partnershipId,
      message,
    });
  }
}

function* getOrganisationalUnits() {
  try {
    yield put({
      type: actionTypes.getOrganisationalUnits().loading(),
    });

    const { data: organisationalUnits } = yield call(organisationalServiceApi.fetchOrganisationalUnits, {
      q: '',
      page: 1,
      page_size: 500,
    });

    yield put({
      type: actionTypes.getOrganisationalUnits().success(),
      organisationalUnits,
    });
  } catch ({ message }) {
    yield put({
      type: actionTypes.getOrganisationalUnits().failed(),
      message,
    });
  }
}
