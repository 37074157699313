import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';

import { generateColor } from '@yojee/helpers/ColorGenerator';

const moment = extendMoment(Moment);

const mapTour = ({ route, i, tasksDataObj, settings }) => {
  route.color = generateColor(i);

  return {
    ...route,
    tour: route.tour.map((t) => {
      const taskData = tasksDataObj[parseInt(t.id)];
      return { ...t, task: taskData ? { ...taskData, stop_id: taskData.stop_id || taskData.id } : taskData };
    }),
  };
};

const mapDroppedTasks = ({ settings, resultData, tasksDataObj }) => {
  // Set dropped task information from taskData
  return resultData.droppedTasks.map((tid) => tasksDataObj[tid]);
};

export const mapResponseData = ({ resultData, tasksDataObj, settings }) => {
  if (resultData?.routes) {
    return {
      ...resultData,
      routes: resultData.routes.map((route, i) => mapTour({ route, i, tasksDataObj, settings })),
      droppedTaskData: mapDroppedTasks({ settings, tasksDataObj, resultData }),
      epoch: settings.epochDate ? moment(settings.epochDate).utc().tz(settings.timezone) : moment(),
    };
  }

  return resultData;
};

export const getErrorMessage = (optimisationStatusData) => {
  if (optimisationStatusData.message && optimisationStatusData.status === 'failed') {
    return optimisationStatusData.message;
  }

  return 'Optimisation failed: something is wrong with your capacity configuration and tasks provided';
};
