import JSZip from 'jszip';

export async function downloadFilesFromUrlsAsZip(urls: string[], preferredZipFileName: string) {
  const promises = urls.map((url) => getFileNameBlobPairFromUrl(url));
  const fileNameBlobPairs = await Promise.all(promises);

  const zip = new JSZip();
  fileNameBlobPairs.forEach(({ fileName, fileBlob }) => {
    zip.file(fileName, fileBlob, { binary: true });
  });

  const content = await zip.generateAsync({ type: 'blob' });

  downloadFileBlob(content, preferredZipFileName);
}

export async function downloadFileFromUrl(url: string, preferredFileName?: string) {
  const { fileName, fileBlob } = await getFileNameBlobPairFromUrl(url, preferredFileName);
  downloadFileBlob(fileBlob, fileName);
}

async function getFileNameBlobPairFromUrl(url: string, preferredFileName?: string) {
  const res = await fetch(url);
  if (res.status !== 200) throw new Error('Fail to fetch file from url');

  const fileBlob = await res.blob?.();

  let fileName = preferredFileName ?? getFileNameFromUrl(url);
  fileName = correctExtensionInFileName(fileName, res);

  return { fileBlob, fileName };
}

function getFileNameFromUrl(url: string) {
  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  const fileName = pathname.split('/').pop();
  if (!fileName) throw new Error('Failed to extract file name from URL');
  return fileName;
}

function downloadFileBlob(fileBlob: Blob, fileName: string) {
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(fileBlob);
  a.download = decodeURI(fileName);
  a.click();
}

const CONTENT_TYPE_EXT_MAP: Record<string, string> = {
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
};

function correctExtensionInFileName(fileName: string, httpResp: Response) {
  const [nameWithoutExt, ext] = splitFileName(fileName);

  const contentType = httpResp.headers?.get('content-type') as string;
  const contentTypeExt = CONTENT_TYPE_EXT_MAP[contentType];
  if (!contentTypeExt) return fileName;

  if (ext !== contentTypeExt) return `${nameWithoutExt}.${contentTypeExt}`;

  return fileName;
}

function splitFileName(fileName: string) {
  const parts = fileName.split('.');
  if (parts.length === 1) return [fileName, null];

  const extention = fileName.split('.').pop();
  const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
  return [nameWithoutExt, extention];
}
