import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { getHeaders } from './common/common';

const getWorkerParams = (additionalParams = {}) => {
  !additionalParams.target_location && delete additionalParams.target_location;
  return {
    ...additionalParams,
  };
};

const MAX_PAGE_SIZE = 1000;

export class WorkersService extends BaseService {
  constructor({ auth }) {
    super();
    this.authService = auth;
  }

  fetchWorkers = ({ query, pageSize = MAX_PAGE_SIZE }) => {
    const apiUrl = query
      ? `dispatcher/search/worker?page=1&page_size=${pageSize}&q=${query}&include_system_driver=true`
      : `dispatcher/workers?page=1&page_size=${pageSize}&include_system_driver=true`;

    return this.authService
      .get(this.getUmbrellaApiUrl(apiUrl))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  fetchPlannerWorkers = ({
    filters: { centroid = null, from = null, to = null } = {},
    sortBy = 'last_seen',
    orderBy = 'desc',
    pageSize = MAX_PAGE_SIZE,
    page = 1,
  }) => {
    const targetLocation = centroid && centroid.lat && centroid.lng ? `${centroid.lat},${centroid.lng}` : '';

    return this.authService.get(this.getUmbrellaApiUrl('dispatcher/workers'), {
      page,
      page_size: pageSize,
      from,
      to,
      sort: sortBy,
      order: orderBy,
      include_system_driver: true,
      ...(targetLocation && { target_location: targetLocation }),
    });
  };

  fetchVehicles = ({ query, pageSize = MAX_PAGE_SIZE }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/vehicles`), { page: 1, page_size: pageSize, q: query })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : [];
      })
      .catch((error) => this.handleError(error));
  };

  fetchWorkersById = ({ token, partnerJwt, slug, ids, pageSize = 1000, page = 1 }) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl('dispatcher/workers'),
        getWorkerParams({ page_size: pageSize, page, worker_ids: ids }),
        {
          headers: getHeaders({ token, slug, partnerJwt }),
        }
      )
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  fetchWorkersSchedule = ({ workerIds }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/schedules'), { worker_ids: workerIds })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  quickAssign = (workerId, taskIds) => {
    const params = {
      task_ids: taskIds,
      worker_id: workerId,
    };

    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/tasks/quick_assign_bg'), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  pollQuickAssignStatus = (quickAssignJobId) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/tasks/bg_status'), { id: quickAssignJobId })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  assignVehicleToWorker = ({ vehicleId, workerId }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/workers/${workerId}/assign_vehicle`), { vehicle_id: vehicleId })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const workersService = new WorkersService({ auth: authService });
